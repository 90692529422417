import React from 'react';
import './form.scss';
import Messengers from "../UI/messengers/Messengers";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Button from "../UI/button/Button";
import Api from "../../utils/api";
import { currentDate } from "../../utils/currentDate";
import common_data from '../../data/data.json';

const Form = ({ data, cls, image, handleSendData }) => {
	const [formData, setFormData] = React.useState({
		messenger_type: 'call',
		phone: '',
		form_name: data.title,
		page_url: window.location.href,
		ip: '0.0.0.0',
		vs_uuid: '',
	});

	const [hasError, setError] = React.useState(false);
	const [filled, setFilled] = React.useState(false);
	const [dialCode, setDialCode] = React.useState(7);

	const handlerRadio = (e) => {
		setFormData({ ...formData, messenger_type: e.target.value });
	};
	const handlerInput = (value) => {
		setFormData({ ...formData, phone: value });
		setError(false);
		setFilled(value.length > 1);
	};
	const sendForm = () => {
		if (formData.phone.length < 10) {
			setError(true);
		}
		if (formData.phone.length >= 11) {
			Api.post( 'https://kf.expert/api/v1/forms/call-me' || data.action, formData);
			if (window.Comagic && window.Comagic.addOfflineRequest) {
				// window.Comagic.addOfflineRequest({
				// 	phone: formData.phone || '',
				// });
			}
			setFormData({ ...formData, phone: `${dialCode}`, messenger_type: 'call' });
			handleSendData();
			if (data.pdf) {
				window.open(data.pdf, '_blank', 'noopener,noreferrer');
			}
		}
	};

	return (
		<div className={`form ${data.v_2 ? 'form--v-2' : ''} ${cls || ''}`}>
			<div className="form__bg-box">
				<div className="form__bg"/>
			</div>
			{ image && data.pdf ?
				<div className='form__image-box'>
					<img src={image} alt="KF Expert"/>
				</div> : null
			}
			<div className="form__content">
				<div className="form__title">{data.title}</div>
				<div className="form__text">{data.text}</div>
				<form action={data.action} className="form__form">
					{ data.link ? (
						<>
							<a href={data.link.href} className="button form__form-button">{data.link.text}</a>
						</>
					) : (
						<>
							<Messengers cls='form__messengers' active={formData.messenger_type} prefix={data.prefix} handleChange={handlerRadio} isEn={data.en} data={data.messengers}/>
							<div className="form__form-group">
								<ReactPhoneInput
									country={data.country_code || 'ru'}
									value={formData.phone}
									onChange={(value, country) => {
										handlerInput(value, country.dialCode);
										setDialCode(country.dialCode);
									}}
									containerClass={`form__phone ${hasError ? 'form__phone--error' : ''} ${filled ? 'form__phone--filled' : ''}`}
								/>
								{hasError && <span className='form__form-error'>Ошибка</span>}
								<Button type='button' cls='form__form-button' text={data.button || 'Получить каталог'} handlerClick={sendForm}/>
							</div>
							<div className="form__agreement">{ data.privacy?.text || 'Нажимая на кнопку, Вы соглашаетесь с '  }<a href={common_data.privacy_policy || 'https://kf.expert/privacy-policy'} rel='noreferrer' target='_blank'>{data.privacy?.link_text || '«Политикой конфиденциальности»'}</a></div>
							{ data.info && data.info.length ? data.info.map((el, index) =>
								<span className="form__info" key={el + index}>{el}</span>
							) : null }
							{ data.pdf && <span className="form__info">{ currentDate(data.en) }</span> }
						</>
					)}
				</form>
			</div>
		</div>
	);
};

export default Form;
